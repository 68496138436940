@import "theme";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $light;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}

html {
  font-size: 19px;
}

@media (max-width: 900px) {
  html {
    font-size: 16px;
  }
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  position: relative;
  padding-bottom: 7.875rem;
  display: flex;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

strong {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.container {
  max-width: 768px;
}

#root {
  display: flex;
  width: 100%;
  justify-content: center;
}

.m {
  &-lg {
    margin: 8.125rem;

    &-s {
      margin-left: 8.125rem;
    }

    &-e {
      margin-right: 8.125rem;
    }

    &-t {
      margin-top: 8.125rem;
    }

    &-b {
      margin-bottom: 8.125rem;
    }
  }
}

.w-max-content {
  width: max-content !important;
}

.btn {
  --bs-btn-border-width: 2px;
  display: flex;
  border-radius: 0.625rem;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1;
  gap: 0.5rem;

  &-lg {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1;
    padding: 1.5rem 2.5rem;
    min-width: 15.5rem;
  }

  &-sm {
    padding: 0.625rem 0.75rem;
  }

  &-rather {
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0.5rem 1.5rem;

    &.btn-sm {
      padding: 0.5rem 0.75rem;
    }
  }

  &-back {
    width: max-content !important;
    padding: 0.625rem 0.75rem;

    &.btn-sm {
      padding: 0.5rem 0.5rem;
    }
  }

  &-sm {
    padding: 0.625rem 1rem;
  }

  &-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    gap: 0.75rem;

    li {
      flex: 1 1;

      .btn {
        width: 100%;
        height: 100%;
      }
    }

    .back-li {
      flex: 0 1 auto;
    }
  }

  &-cover {
    padding: 0;
    border: 0;
    border-radius: 0;
    width: 100%;
    text-decoration: none;
    height: 100%;

    .cust-radio-card {
      padding: 0.75rem 0.5rem;
    }

    .cust-radio-title {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
      color: #000000;
      text-decoration: none;
    }

    .cust-radio-badge {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }

  &-outline {
    &-primary {
      img {
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        img {
          -webkit-filter: grayscale(1) invert(1) brightness(1);
          filter: grayscale(1) invert(1) brightness(1);
        }
      }
    }
  }
}

.table {
  margin-bottom: 0;

  > :not(caption)>*>* {
    padding: 0.625rem 0;
  }

  >tbody {
    vertical-align: inherit;
    font-weight: 400;
    font-size: 0.5rem;
    line-height: 0.625rem;
    color: #000000;
  }

  >thead {
    vertical-align: top;
    font-weight: 400 !important;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: var(--bs-primary);
    border-bottom: 1px solid rgba(177, 177, 177, 0.3);
  }
}

.text-sm {
  font-weight: 300;
  font-size: 0.65rem;
  line-height: 1rem;
}

.page {
  &-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.375rem;
    color: var(--bs-primary);
    text-align: center;

    &-alt {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.375rem;
      text-align: center;
    }
  }

  &-header {
    background: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    border-radius: 0.3125rem 0.3125rem 0px 0px;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: #ffffff;
  }
}

.title {
  &-sm {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  &-md {
    font-size: 1.25rem;
    line-height: 1.2;
  }

  &-lg {
    font-size: 2.25rem;
    line-height: 1.2;
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  &-block {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
  }

  &-logo {
    margin-top: auto;
    padding: 2rem 0;
  }
}

.form {
  &-label {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    color: #474747;
  }
}

.form-control,
.form-select {
  border-radius: 0.3125rem;
  border: 0;
  background-color: #e8f0fe;
  padding: 0.3125rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: #000000;
}

.form-check {
  display: flex;
  padding-left: 0;
  margin-bottom: 1rem;
  gap: 0.75rem;

  &-input {
    width: 1.125rem;
    height: 1.125rem;
    background: var(--bs-light);
    border: 1px solid var(--bs-primary);
    border-radius: 0;
    flex: 0 0 auto;
    float: none;
    margin-left: 0 !important;

    &[type="checkbox"] {
      border-radius: 0;
    }

    &:checked {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    }

    &:focus {
      border-color: var(--bs-primary);
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
    }
  }

  &-label {
    margin-top: 0.4rem;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.input-group {
  border-radius: 0.3125rem;

  &-text {
    padding: 0.5rem 0.75rem;
    padding-right: calc(0.75rem + 0.3125rem);
    color: #474747;
    background-color: #ffffff;
    border: 0;
    border-radius: 0.3125rem;
    border: 1px solid #e5e5e5;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }

  &.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
  &.has-validation>.dropdown-toggle:nth-last-child(n + 4),
  &.has-validation>.form-floating:nth-last-child(n + 3)>.form-control,
  &.has-validation>.form-floating:nth-last-child(n + 3)>.form-select {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }

  &>.form-control,
  &>.form-select,
  &>.form-floating {
    margin-left: -0.3125rem !important;
  }
}

.cust-radio {
  display: flex;
  flex-wrap: wrap;
  gap: 1.75rem;
  margin: auto;
  padding-bottom: 5rem;
  width: 100%;
  justify-content: center;

  input {
    visibility: hidden;
    position: absolute;

    &:checked+.cust-radio-card {
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
      border: 2px solid var(--bs-primary);
    }
  }

  &-content {
    width: calc(50% - 0.875rem);
    max-width: 200px;
  }

  &-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-card {
    background: #ffffff;
    border-radius: 0.3125rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1.25rem 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: all 0.2s ease-in-out;

    &:hover {
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    }
  }

  &-title {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    text-align: center;
    margin-bottom: 0.625rem;
  }

  &-badge {
    background-color: var(--bs-primary);
    border-radius: 20px;
    padding: 0.375rem 1.25rem;
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 1rem;
    margin: auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    width: max-content;
    margin-top: 1.25rem;
  }
}

.invalid-feedback,
.valid-feedback {
  font-weight: 300;
  font-size: 0.65rem;
  line-height: 1rem;
}

.cover-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;

  &-item {
    display: flex;
    width: 100%;
  }

  &-input {
    display: flex;

    input {
      margin-top: auto;
      margin-bottom: 0.25rem;
    }
  }

  &-slider {
    padding-left: 0.75rem;

    &-container {
      flex: 1 1 auto;
      padding-right: 2rem;
    }
  }

  &-cost {
    display: flex;
    flex-direction: column;
    width: 50px;
    margin-left: auto;
    margin-bottom: 0.25rem;
  }

  &-title {
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 1.25rem;

    &-total {
      font-weight: 700;
      font-size: 0.625rem;
      line-height: 1.25rem;
      text-align: right;
    }
  }

  &-price {
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 1.25rem;
    color: var(--bs-primary);
    margin-top: auto;

    &-total {
      font-weight: 700;
      font-size: 0.625rem;
      line-height: 1.25rem;
    }
  }
}

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 1;
  color: #000000;
  background-color: #ffffff;
  padding: 0.25rem 0.75rem;
  height: auto;
  max-width: max-content;
  opacity: 1;
  top: -8px;

  @media (max-width: 991px) {
    padding: 0.25rem 0.5rem;
    top: unset;
  }
}

.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--top .range-slider__tooltip__caret {
  display: none;
}

.range-slider__wrap.range-slider__wrap--sm .range-slider__tooltip--top {
  bottom: 19px;
}

input[type="range"].range-slider.range-slider--sm {
  padding-bottom: 0;
}

.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--on.range-slider__tooltip--disabled {
  opacity: 1;
}

.range-slider__wrap .range-slider__tooltip {
  z-index: 99;
}

.cover-section {
  padding: 1rem 2rem;
}

.modal {
  &-dialog {
    margin: 1.75rem;

    @media (min-width: 576px) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &-content {
    background: rgba(255, 255, 255, 1);
    border: 2px solid #c3fbc2;
    border-radius: 20px;
  }

  &-header {
    padding: 1.25rem 1.125rem;
    border-bottom: 0;
    position: relative;

    .btn-close {
      position: absolute;
      top: -1.125rem;
      right: -1.125rem;
      width: 2.25rem;
      height: 2.25rem;
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23C3FBC2'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") (center / 1.5rem) auto no-repeat;
      background-color: #ffffff;
      border: 2px solid #c3fbc2;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 1051;
      opacity: 1;
      transition: all 0.2s ease-in-out;

      &:hover,
      &:focus {
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23acdfab'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") (center / 1.5rem) auto no-repeat;
        background-color: #ffffff;
        border: 2px solid #acdfab;
      }
    }
  }

  &-title {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--bs-primary);
  }

  &-body {
    padding: 1.25rem 1.125rem;
    padding-top: 0;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
}

.price-table {
  &> :not(caption)>*>* {
    padding: 0.325rem 0;
    padding-right: 1.25rem;
  }

  >tbody {
    font-style: normal;
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: var(--bs-primary);
  }
}

.img-uploader-label {
  max-width: 600px;
}

.sign-pad {
  canvas {
    width: 100%;
    max-width: 600px;
    height: 300px;

    @media (max-width: 768px) {
      max-width: 400px;
      height: 200px;
    }

    @media (max-width: 576px) {
      max-width: 300px;
      height: 150px;
    }
  }
}

.btn-wrapper {
  max-width: max-content;
}

.loaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.terms-container {
  overflow-y: auto;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
  height: 45vh;
  margin-top: 1rem;
}

.terms-container h1,
.terms-container h2,
.terms-container h3,
.terms-container h4,
.terms-container h5,
.terms-container h6 {
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.terms-container .blue-label {
  color: #4068a1;
  font-weight: 700;
}

.terms-container p {
  margin-bottom: 0;
}

.otp-input>div {
  justify-content: center;
  flex-wrap: wrap;
}

.otp-input input {
  border-radius: 0.3125rem;
  background-color: #e8f0fe;
  padding: 0.3125rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: #000000;
  display: block;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 40px !important;
  height: 60px;
  margin: 10px;
}

.terms-text {
  font-size: 14px;
  padding: 10px;
  line-height: 16px;
}

.cust-table th {
  text-align: center;
}

.cust-table th,
.cust-table td {
  border: 1px solid #474747;
  padding: 5px;
}