/* import the necessary Bootstrap files */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

/* -------begin customization-------- */

/* or, use an existing variable */
$primary: #0b58a6;
$secondary: #97a2ac;
$light: #d9d9d9;
$danger: #f38f91;
$success: #0ba636;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "light": $light,
  "danger": $danger,
  "success": $success,
);
/* -------end customization-------- */
@import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider";
/* finally, import Bootstrap to set the changes! */
@import "~bootstrap/scss/bootstrap";
